@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Thai:wght@100;200;300;400;500;600;700;800;900&display=swap');

html, body, #root {
    height: 100%;
}

body {
    font-family: 'Noto Serif Thai', Roboto, sans-serif;
    font-size: 16px;
    background-color: #0e1948;
    /*background-color: #091132;*/
    color: #fff;
    font-weight: 400;
    overflow-x: hidden;

}

body {
    background-position: 0px 61px;
    background-size: contain;
    background-repeat: no-repeat;
}

body.login {
    background-color: #091132;
}

@media (max-width: 1199px) {
    #main-body {
        padding-bottom: 100px;
    }
}

#main-body {
    /*max-width: 640px;*/
}

a {
    color: #fff;
}

a:hover {
    color: #fff;
    text-decoration: none;
}

img {
    max-width: 100%;
}

hr {
    border-top: 2px solid #243E5B;
}

hr.sm {
    border-top: 1px solid #243E5B;
}

.row.row-sm {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
}

.row.row-sm > [class*="col-"] {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.row.row-ssm {
    margin-right: -0.2rem;
    margin-left: -0.2rem;
}

.row.row-ssm > [class*="col-"] {
    padding-right: 0.2rem;
    padding-left: 0.2rem;
}

.text-danger {
    color: #EB2A34 !important;
}

.text-shadow {
    text-shadow: 1px 1px 1px #000000 !important;
}

.text-bold {
    font-weight: 500 !important;
}

.form-control:focus,
.form-control:active,
.form-control {
    border-color: #243E5B !important;
    background-color: #001a35 !important;
    color: #fff !important;
    font-weight: 400;
}

.form-control:disabled, .form-control[readonly] {
    background-color: transparent !important;
    opacity: 1;
    cursor: not-allowed;
}

.form-control option {
    color: #333;
}

.form-control,
select.form-control option {
    color: #cdcdcd !important;
}

.input-group-text {
    background-color: #04111e;
    border-color: #243E5B;
    color: #fff;
}


.btn:focus, .btn:active, .form-control:focus-visible,
.form-control:focus, .form-control:active {
    outline: none !important;
    box-shadow: none !important;
    background-color: inherit;
}

.btn:not(.btn-primary) {
    padding: .48rem 1rem;
}

.nor {
    border-radius: 0 !important;
}

@media (max-width: 1500px) {
    .col-lg-c9 {
        width: 100% !important;
        max-width: 100% !important;
        flex: 0 0 100% !important;
    }
}


.btn-primary {
    background: linear-gradient(90deg, #C90567 1.68%, #FD2638 99.11%) !important;
    color: #fff;
    border: none !important;
}

.btn-secondary {
    background-color: #243E5B;
    border-color: #243E5B;
}

.bg-outline {
    border: 1px solid #243E5B;
    color: rgba(36, 62, 91, 1) !important;
}

.bg-primary {
    background: linear-gradient(90deg, #C90567 1.68%, #FD2638 99.11%) !important;
}

.btn-dark {
    background-color: #04111e !important;
    border-color: #04111e !important;
}

.border,
[class*="border-"] {
    border-color: #243E5B !important;
}

.border-width {
    border-width: 2px !important;
}

.header {
    background-color: #071130;
}

.header a {
    color: #fff;
}

.input-group-custom .btn,
.input-group-custom .form-control,
.header .btn,
.header .form-control {
    border-color: #243E5B !important;
    border-width: 2px;
    height: 40px;
}

.input-group-custom .form-control,
.header .form-control {
    background-color: transparent;
}

.input-group-custom .btn,
.header .btn {
    margin-right: -2px;
    background-color: #243E5B !important;
    color: #fff !important;
}

.navbar-brand img {
    height: 50px;
}

@media (max-width: 560px) {
    .navbar-brand img {
        height: 30px;
    }

    .navbar-brand {
        margin-right: 0;
    }
}

@media (min-width: 1200px) {

    .header .navbar-expand-lg {
        justify-content: space-between;
        align-items: center;
    }

    .header .navbar-collapse {
        flex-grow: unset;
    }

    .header .nav-link svg {
        font-size: 36px;
    }

    .header input.form-control {
        width: 500px;
    }

    .header .navbar-nav .nav-item:not(:last-child) a {
        position: relative;
    }

    .header .navbar-nav .nav-item:not(:last-child) a:after {
        content: '';
        height: 45%;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        border-right: 1px solid rgba(255, 255, 255, 0.53);
        position: absolute;
    }
}

.header .nav-link {
    font-weight: 300;
}


.box {
    border-radius: 8px;
    /*overflow: hidden;*/
    background-color: #071130;
}

.breadcrumb {
    background-color: #071130;
}

.heading {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #fff;
    position: relative;
}

.heading.line-o {
    border-bottom: 1px solid #243E5B;
}

.heading:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 130px;
    border-bottom: 1px solid #FFD600;
}

.navbar-toggler i,
.navbar-toggler svg {
    color: #fff;
}

.main {
    display: flex;
    align-items: stretch;
    min-height: calc(100% - 52px);
}

.sidebar {
    position: relative;
    width: 100%;
    background-color: #071130;
    margin-top: 1rem;
    z-index: 99;
    box-shadow: none;
    transition: all ease-in-out .15s;
    flex: 0 0 auto;
    width: 16.875rem;
}

@media (min-width: 1200px) {
    .sidebar {
        display: none;
    }
}

.sidebar, .content-page {
    /*height: calc(100% - 2rem);*/
}

@media (max-width: 767px) {
    .content-page {
        margin: 0 !important;
    }

    .content-page .content-page-inner {
        padding: 1rem 0;
    }
}

.sidebar ul .close {
    display: none;
}

@media (max-width: 1300px) {
    .sidebar {
        right: -100%;
        position: fixed;
        height: 100%;
        margin-top: 0;
        top: 0;
        transition: all .3s;
        background-color: transparent;
    }

    .sidebar.sidebar-show:after {
        content: '';
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        top: 0;
        right: 0;
    }

    .sidebar.sidebar-show {
        right: 0;
        width: 100%;
        z-index: 9999;
    }

    .sidebar ul {
        margin: 0 0 0 auto !important;
        position: relative;
    }

    .sidebar ul .close {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(-100%);
        background-color: #071130;
        padding: 5px 15px 10px 15px;
        color: #fff;
        z-index: 88888888;
        font-weight: 400;
        line-height: 1;
        text-shadow: none;
        cursor: pointer;
        opacity: 1;
    }

    .sidebar ul .close:hover {
        opacity: 1 !important;
    }

    .sidebar ul {
        position: relative;
        z-index: 99999;
        background-color: #071130;
        height: 100%;
        width: 280px;
    }

}

.sidebar ul {
    list-style: none;
    margin: 0;
    padding: 15px 0;
}

.sidebar ul li {
    margin-bottom: 1px;
    position: relative;
}

.sidebar li.nav-item-header {
    padding: .75rem 1.25rem;
    margin-top: .5rem;
}

.sidebar ul li:hover:after,
.sidebar ul li.active:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 90%;
    background-color: #000A14;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}

.sidebar ul li:hover:before,
.sidebar ul li.active:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 10;
    border-left: 3px solid #FFD600;
}

.sidebar ul li:hover *,
.sidebar ul li.active * {
    color: #FFD600;
    text-decoration: none;
}

.sidebar li a {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: .75rem 1.25rem;
    transition: background-color ease-in-out .15s, color ease-in-out .15s;
    font-size: 14px;
    font-weight: 500;
    z-index: 99;
}

.sidebar li a svg {
    margin-right: 1.25rem;
    margin-top: .15rem;
    margin-bottom: .12502rem;
    top: 0;

    min-width: 1em;
    display: inline-block;
    text-align: center;
    font-size: 1rem;
    vertical-align: middle;
    position: relative;
}

.sidebar li a img {
    margin-right: 1.25rem;
}

.sidebar li a:hover span,
.sidebar li a.active span {
    background: -webkit-linear-gradient(#FFD600, rgba(235, 42, 52, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}


.content-page {
    flex: 1 1 auto;
    flex-grow: 1;
    width: 100%;
    margin: 1rem 1rem 0 1rem;
}

@media (min-width: 1200px) {
    .banner img {
        width: 100%;
    }
}

.underline-red {
    border-bottom: 1px solid #EB2A34;
}


.box-inline-center {
    display: flex;
    align-items: center;
    justify-items: center;
}

.box-inline-center .logo {
    width: 80px;
    background-color: #ddd;
    border-radius: 10px;
    height: 80px;
    overflow: hidden;
    flex: 0 0 auto;
    margin-right: 10px;
}

.bank-icon-logo.has-logo,
.box-inline-center .logo.has-logo {
    background-color: transparent !important;
}

.bank-icon-logo {
    width: 106px;
    background-color: #ddd;
    border-radius: 10px;
    height: 106px;
    overflow: hidden;
    flex: 0 0 auto;
    margin-right: 10px;
}

.box-inline-center .content {
    flex: 1 1 auto;
    flex-grow: 1;
    width: 100%;
}


.table td {
    color: #ababab;
    font-weight: 300;
}

.game-item {
    position: relative;
}

.game-item .name,
.game-item small {
    line-height: 1.2;
    font-size: 80%;
}

.game-item {
    cursor: pointer;
}

.game-item img {
    border-radius: 5px;
}

.game-item .name {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 10px;
    background: linear-gradient(rgba(9, 15, 23, 0) 0%, rgba(9, 14, 22, 0.56) 100%);
}


.open-game-popup {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.9);
    width: 100%;
    height: 100%;
    z-index: 9999999999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.open-game-popup-frm {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 9999999999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.open-game-popup-frm iframe {
    width: 100%;
    height: 100%;
    border: none;
}

/*@media (min-width: 1200px) {*/
/*    */
/*}*/

.open-game-popup-frm .close-game {
    position: absolute;
    z-index: 9999999998;
    top: 0;
    right: 0;
    background-color: #0e1948;
    color: #fff;
    padding: 5px 10px;
    box-shadow: none;
    outline: none;
    border: none;
}

.open-game-popup-frm .close-game svg {
    font-size: 22px;
}

.popup-alert {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999999999;
    width: 500px;
    background-color: #071130;
}

.popup-alert .content {
    margin: 10px;
    border: 1px dashed #032b51;
    padding: 20px;

}

.notification-container .notification .title {
    font-weight: 500 !important;
}


.box-login {
    overflow: hidden !important;
    border-radius: 20px;
}


@media (max-width: 992px) {
    .border-right {
        border-color: transparent !important;
    }
}


.heading-title {
    border-left: 3px solid #FFD600;
    padding-left: 5px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.heading-title h3 {
    font-weight: 400;
    font-size: 22px;
    line-height: 1;
    margin-bottom: 5px;
    color: #fff;
}

.heading-title div {
    font-size: 14px;
    font-weight: 300;
    color: rgb(255 214 1);

}

.heading-title div div {
    display: flex;
    align-items: center;
}

.heading-title div svg {
    margin-right: 3px;
}

.heading-title .back {
    color: #89969F;
    font-size: 20px;
    font-weight: 500;
    position: relative;
    z-index: 999999999999999;
}

.heading-title .back:hover {
    text-decoration: none;
}

@media (max-width: 786px) {
    .heading-title.sm h3 {
        font-size: 16px;
    }

    .heading-title .back {
        font-size: 14px;
    }

    .heading-title h3 {
        font-size: 18px;
    }

    .heading-title div {
        font-size: 12px;
    }
}

.circle-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.circle-amount {
    border-width: 3px;
}

.circle-amount, .circle-amount-inner {
    border-radius: 50%;
    border-color: #FFD600;
    border-style: solid;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circle-amount-inner {
    text-align: center;
    background-color: #071130;
    border-width: 1px;
}

.circle-amount-inner .content .title {
    color: #89969F;
}

.circle-amount-inner .content .unit {
    font-size: 42px;
}

@media (min-width: 1200px) {
    .circle-amount {
        width: 350px;
        height: 350px;
    }

    .circle-amount-inner {
        width: 300px;
        height: 300px;

    }
}

@media (max-width: 1199px) {
    .circle-amount {
        width: 300px;
        height: 300px;
    }

    .circle-amount-inner {
        width: 250px;
        height: 250px;
    }
}

@media (max-width: 767px) {
    .circle-amount {
        width: 280px;
        height: 280px;
    }

    .circle-amount-inner {
        width: 230px;
        height: 230px;
    }
}


.item-button {
    padding: 15px 0;
    background: rgb(0, 14, 58);
    background: linear-gradient(180deg, rgba(0, 14, 58, 1) 0%, rgba(0, 14, 58, 1) 25%, rgba(2, 9, 31, 1) 100%);
    font-size: 13px;
}

.item-button svg {
    font-size: 22px;
    display: block;
    margin: 0 auto;
    margin-bottom: 5px;
}

.item-button a:hover {
    text-decoration: none;
    color: #fff;
    opacity: 1;
}


.number-box {
    background-color: #071130;
    border-bottom: 1px solid #FFD600;
    padding: 15px 0;
    text-align: center;
}


.table-custom td {
    background: rgb(0, 14, 58);
    background: linear-gradient(180deg, rgba(0, 14, 58, 1) 0%, rgba(0, 14, 58, 1) 25%, rgba(2, 9, 31, 1) 100%);
    border-top: none !important;
}


.pagination .page-item.active .page-link {
    background-color: #FFD600;
    border-color: #FFD600;
    color: #000;
}

.pagination .page-item.disabled .page-link,
.pagination .page-link {
    background-color: #071130;
    border-color: #071130;
    color: #fff;
}

.pagination .page-item.disabled .page-link {

}


.footer-menu {
    position: fixed;
    width: 98%;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #5F5F5F;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-size: 14px;
    z-index: 888888;
}

@media (min-width: 1200px) {
    .footer-menu {
        display: none;
        max-width: 1190px;
    }

    .footer-menu svg {
        font-size: 36px;
    }
}

.footer-menu svg {
    font-size: 28px;
    display: block;
    margin: 0 auto;
    margin-bottom: 5px;
}

@media (max-width: 991px) {
    .react-datepicker-wrapper .form-control,
    .react-datepicker-wrapper {
        width: 100%;
    }

}

@media (max-width: 767px) {
    .w-sm-100 {
        width: 100%;
        flex: 1 1 auto;
        margin-right: 0 !important;
        margin-top: 0 !important;
    }
}

/*.react-datepicker-wrapper{*/
/*    width: 100%;*/
/*}*/


.swiper-slide {
    width: 20%;
}

@media (max-width: 767px) {
    .swiper-slide {
        width: 38%;
    }
}

@media (min-width: 992px) {
    .cover {
        background-image: none !important;
    }
}

.cover {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
}

.category {
    display: none;
}

.category p {
    font-size: 12px;
}

@media (max-width: 991px) {

    .cover {
        /*background-image: none !important;*/
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        margin: 0;
        height: 600px;
        padding: 1rem 1.9rem 1rem 1.9rem;
    }

    .cover + .row {
        margin-top: 30%;
    }

    .category {
        display: flex;
        position: absolute;
        top: 30%;
        left: 0;
    }

    .category .inner {
        padding: 0 1.9rem;
    }
}

@media (max-width: 767px) {
    .category {
        display: flex;
        position: absolute;
        bottom: 50%;
        left: 0;
    }

    .cover {
        height: 400px;
    }
}

@media (max-width: 568px) {
    .cover {
        height: 300px;
    }
}


.icon-spin svg {
    -webkit-animation: icon-spin 2s infinite linear;
    animation: icon-spin 2s infinite linear;
}

@-webkit-keyframes icon-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@keyframes icon-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

.pointer {
    cursor: pointer;
}

@media (max-width: 767px) {
    .link-mobile-input {
        font-size: 12px;
    }
}

.ref-title{
    font-size: 18px;
    border-left: 3px solid #ffc107;
    padding: 5px 10px 5px 10px;
    background-color: #04111e;
}


.img-card-placeholder {
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 100%;
    display: block;
    position: relative;
    overflow: hidden;
}

.img-card-placeholder .image {
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: all .3s;
}

.img-card-s1 {
    padding-top: 110%;
}

.img-card-s2 {
    padding-top: 60%;
}

.img-card-s3 {
    padding-top: 150%;
}

.img-card-thumbnail {
    padding-top: 125%;
}
